<template>
	<div>
		<div
				class="font-title text-center mb-2"
				style="letter-spacing: 0.5rem; font-size: 2rem"
		>
			海境界家园二期数据管理平台
		</div>
		<b-row class="mt-1">
			<b-col cols="4">
				<b-row>
					<b-col cols="6">
						<b-card>
							<b-card-title class="font-title">
								<feather-icon
										class="mr-1"
										icon="GridIcon"
										size="18"
								/>
								建筑信息
							</b-card-title>
							<b-card-text>
								<b-row>
									<b-col class="text-center px-0" cols="4">
										<b-avatar
												class="mb-50"
												size="36"
												variant="light-primary"
										>
											<feather-icon
													icon="HexagonIcon"
													size="18"
											/>
										</b-avatar>
										<b-card-text>
											<div class="text-left pl-1">
												<label style="margin-top: 0.9rem">建筑面积:</label>
											</div>

											<h4 class="my-50">
												<span class="font-digit" style="font-size: 1.2rem">{{ params.info[0].bdarea || 'N/A' }}</span>
												<small class="text-muted">
													m<sup>2</sup></small
												>
											</h4>
										</b-card-text>
									</b-col>

									<b-col class="text-center px-0" cols="4">
										<b-avatar
												class="mb-50"
												size="36"
												variant="light-success"
										>
											<feather-icon icon="LayersIcon" size="18"/>
										</b-avatar>
										<b-card-text>
											<div class="text-left pl-1">
												<label style="margin-top: 0.9rem">建筑类型:</label>
											</div>

											<h4 class="my-50">
												<span class="font-digit" style="font-size: 1.1rem">{{ params.info[0].NAME || 'N/A' }}</span>
											</h4>

										</b-card-text>
									</b-col>

									<b-col class="text-center px-0" cols="4">
										<b-avatar
												class="mb-50"
												size="36"
												variant="light-warning"
										>
											<feather-icon icon="ClockIcon" size="18"/>
										</b-avatar>
										<b-card-text>
											<div class="text-left pl-1">
												<label style="margin-top: 0.9rem">建筑年份:</label>
											</div>

											<h4 class="my-50">
												<span class="font-digit" style="font-size: 1.2rem"> {{ params.info[0].bddate || 'N/A' }} </span>
											</h4>

										</b-card-text>
									</b-col>
								</b-row>
							</b-card-text>
						</b-card>
					</b-col>
					<b-col cols="6">
						<b-card>
							<b-card-title class="font-title">
								<feather-icon
										class="mr-1"
										icon="GridIcon"
										size="18"
								/>
								碳排放
							</b-card-title>
							<b-card-text>
								<b-row>
									<b-col cols="6" class="pr-0 text-ellipsis">
										<span class="mr-50">能耗:</span>
										<span
												class="text-primary"
												style="font-size: 1.2rem"
										>{{ params.carbon[0].kWh ? parseInt(params.carbon[0].kWh) : 'N/A' }}</span
										>
										<small class="text-muted ml-50"
										>kWh</small
										>
									</b-col>
									<b-col cols="6" class="px-0">
										<span class="mr-50">碳排:</span>
										<span
												class="text-success"
												style="font-size: 1.2rem"
										>{{ params.carbon[0].co2kg ? parseInt(params.carbon[0].co2kg) : 'N/A' }}</span
										>
										<small class="text-muted ml-50"
										>kgCO2</small
										>
									</b-col>
									<b-col cols="12">
										<div class="d-flex align-items-center" style="margin: 1.7rem 0 1.5rem 0">
											<b-avatar
													class="mr-2"
													size="48"
													variant="light-success"
											>
												<h3 class="text-success my-0 ">
													CO<sub>2</sub>
												</h3>
											</b-avatar>
											<b-card-text>
												<h3 class="my-0"><span style="font-size: 1.6rem">{{ params.carbon[0].co2kg ? parseInt(params.carbon[0].co2kg) : 'N/A' }}</span>
													<span class="text-muted font-small-2">&nbsp;kgCO<sub>2</sub></span>
												</h3>
												<b-card-text class="my-0">
													年度碳排放
												</b-card-text
												>
<!--												<b-card-text-->
<!--														class="-->
<!--                                                        text-muted-->
<!--                                                        mb-0-->
<!--                                                        d-flex-->
<!--                                                        align-items-center-->
<!--                                                        font-small-3-->
<!--                                                    "-->
<!--														style="margin-top: 0.5rem"-->
<!--												>-->
<!--													<span>去年 0 </span>-->
<!--													<span-->
<!--													>&nbsp;kgCO<sub>2</sub>&nbsp;</span-->
<!--													>-->
<!--													<div-->
<!--															class="-->
<!--                                                            d-flex-->
<!--                                                            align-items-center-->
<!--                                                            text-success-->
<!--                                                            ml-25-->
<!--                                                        "-->
<!--													>-->
<!--														<span>0%</span>-->
<!--														<feather-icon-->
<!--																icon="TrendingDownIcon"-->
<!--														/>-->
<!--													</div>-->
                        <!--												</b-card-text>-->
                      </b-card-text>
                    </div>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-card>
              <b-card-title class="font-title">
                <feather-icon
                    class="mr-1"
                    icon="GridIcon"
                    size="18"
                />
                分项能耗
                <small class="float-right">kWh</small>
              </b-card-title>
              <b-card-text>
                <div class="d-flex" style="height: calc((100vh - 50rem) * 0.5 - 6rem)">
                  <div class="w-50 h-100 mr-1">
                    <div id="hjj-demo2-chart-9" class="h-100 w-100">

                    </div>
                  </div>
                  <div class="w-50 h-100 ml-3">
                    <div id="hjj-demo2-chart-11" class="h-100 w-100">

                    </div>
                  </div>
                </div>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-card>
              <b-card-title class="font-title">
                <feather-icon
                    class="mr-1"
                    icon="GridIcon"
                    size="18"
                />
                用能趋势
								<small class="float-right">kWh</small>
							</b-card-title>
							<b-card-text>
								<statistic-card-with-area-chart
										v-if="energy_trend.series[0].data.length"
										:chart-data="energy_trend.series"
										:statistic="kFormatter(energy_trend.avg)"
										color="primary"
										icon="ZapIcon"
										statistic-title="日均能耗 (kWh)"
										style="height: 11.1rem"
								/>
                <div v-else style="height: 11.1rem"></div>
							</b-card-text>
						</b-card>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="12">
						<b-card>
							<b-card-title class="font-title">
								<feather-icon
										class="mr-1"
										icon="GridIcon"
										size="18"
								/>
								逐日能耗
								<small class="float-right">kWh</small>
							</b-card-title>
							<b-card-text>
								<div id="hjj-demo2-chart-5" style="height: calc((100vh - 50rem) * 0.5 - 6rem)">

								</div>
							</b-card-text>
						</b-card>
					</b-col>
				</b-row>
			</b-col>
			<b-col cols="4">
				<b-card-text
						class="rounded shadow overflow-hidden"
						style="margin-bottom: 2rem"
				>
					<b-carousel
							id="carousel-fade"
							controls
							fade
							indicators
					>
						<b-carousel-slide
								style="background: url(/images/hjj.png) center center / cover;width: 100%;height: 33.6rem;"
						>
						</b-carousel-slide>
					</b-carousel>
				</b-card-text>

				<b-card>
					<b-row>
						<b-col cols="4">
							<div class="d-flex align-items-center">
								<b-avatar
										class="mr-1"
										rounded
										size="42"
										variant="light-primary"
								>
									<feather-icon
											icon="StarIcon"
											size="24"
									/>
								</b-avatar>
								<b-card-text>
									<h4 class="my-0">
                      <span style="font-size: 1.5rem">
	                      {{
		                      params.carbon[0].kWh ? parseInt(params.carbon[0].kWh) : 'N/A'
	                      }}
                      </span>
										<span class="text-muted font-small-2">&nbsp;kWh</span>
									</h4>
									<label class="my-0">总能耗</label>
								</b-card-text>
							</div>
						</b-col>

						<b-col cols="4">
							<div class="d-flex align-items-center">
								<b-avatar
										class="mr-1"
										rounded
										size="42"
										variant="light-success"
								>
									<feather-icon
											icon="StarIcon"
											size="24"
									/>
								</b-avatar>
								<b-card-text>
									<h4 class="my-0">
										<span style="font-size: 1.5rem">
											{{
												params.carbon[0].co2kg ? parseInt(params.carbon[0].co2kg) : 'N/A'
											}}
										</span>
										<span class="text-muted font-small-2">&nbsp;kgCO<sub>2</sub></span>
									</h4>
									<label class="my-0">总碳排放量</label>
								</b-card-text>
							</div>
						</b-col>

						<b-col cols="4">
							<div class="d-flex align-items-center">
								<b-avatar
										class="mr-1"
										rounded
										size="42"
										variant="light-info"
								>
									<feather-icon
											icon="StarIcon"
											size="24"
									/>
								</b-avatar>
								<b-card-text>
									<h4 class="my-0">
										<span style="font-size: 1.5rem">
											{{
												params.carbon[0].co2kgstrength ? Math.floor(params.carbon[0].co2kgstrength * 100) / 100 : 'N/A'
											}}
										</span>
										<span class="text-muted font-small-2">&nbsp;kgCO<sub>2</sub>/m<sup>2</sup></span>
									</h4>
									<label class="my-0"
									>碳排放强度</label
									>
								</b-card-text>
							</div>
						</b-col>
					</b-row>
				</b-card>

				<b-card>
					<b-card-title class="font-title">
						<feather-icon
								class="mr-1"
								icon="GridIcon"
								size="18"
						/>
						区域能耗
						<small class="float-right">kWh</small>
					</b-card-title>
					<b-card-text>
						<div id="hjj-demo2-chart-8" style="height: calc(100vh - 62.3rem)">

						</div>
					</b-card-text>
				</b-card>
			</b-col>
			<b-col cols="4">
				<b-row>
					<b-col cols="12">
						<real-time-air-quality :title="'标准层室内空气质量'" :vertical="true"
						                       style="height: 33.6rem;"></real-time-air-quality>
					</b-col>

					<b-col cols="12">
						<hours48-params-temp :vertical="true"
						                     style="height: calc((100vh - 50rem) * 0.5);overflow: hidden"></hours48-params-temp>
					</b-col>

					<b-col cols="6">
						<hours48-params-pm25 :vertical="true"
						                     style="height: calc((100vh - 50rem) * 0.5);overflow: hidden"></hours48-params-pm25>
					</b-col>

					<b-col cols="6">
						<hours48-params-humi :vertical="true"
						                     style="height: calc((100vh - 50rem) * 0.5);overflow: hidden"></hours48-params-humi>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import {getMultipleParams} from "@/api/hjj2";
import {Column, Pie} from "@antv/g2plot";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import {kFormatter} from "@core/utils/filter";
import VueApexCharts from "vue-apexcharts";

const $earningsStrokeColor2 = "#28c76f66";
const $earningsStrokeColor3 = "#28c76f33";

export default {
	name: "Hjj2Demo",
	components: {
		StatisticCardWithAreaChart,
		VueApexCharts,
		RealTimeAirQuality: () => import('@/views/front/hjj2/components/RealTimeAirQuality.vue'),
		// RealTimeClock: () => import('@/views/front/hjj2/components/RealTimeClock'),
		Hours48ParamsPm25: () => import('@/views/front/hjj2/components/Hours48ParamsPm25.vue'),
		Hours48ParamsTemp: () => import('@/views/front/hjj2/components/Hours48ParamsTemp.vue'),
		Hours48ParamsHumi: () => import('@/views/front/hjj2/components/Hours48ParamsHumi.vue'),
	},
	data() {
		return {
			interval: null,
			params: {
				info: [{
					NAME: '',
					bdarea: 0,
					bddate: '',
				}],
				energy_d: [],
				energy_type: [],
				zone: [],
				carbon: [{
					kwh: 0,
					co2kg: 0,
					co2kgstrength: 0
				}]
			},
			chart_5: null,
			chart_8: null,
			chart_9: null,
			chart_11: null,
			energy_trend: {
				series: [{
					name: "用能趋势",
					data: [],
				}],
				avg: 0,
			},
		};
	},
	methods: {
		kFormatter,

		//逐日能耗
		chart_init_5() {
			const data = [];
			const columnPlot = new Column("hjj-demo2-chart-5", {
				data,
				xField: "time",
				yField: "value",
				color: "#7367f0",
				label: false,
				xAxis: {
					label: {
						autoHide: true,
						autoRotate: false,
						formatter: (text) => {
							return text.replace(' 00:00:00', '');
						},
					},
				},
				yAxis: {
					nice: true,
					grid: {
						line: {
							style: {
								stroke: "#82868b",
								lineWidth: 1,
								lineDash: [1, 6],
								strokeOpacity: 0.7,
							},
						},
					},
					label: {
						formatter: (text) => {
							return this.kFormatter(text);
						},
					},
				},
				meta: {
					time: {
						alias: "时间",
						formatter: (text) => {
							return text.replace(' 00:00:00', '');
						},
					},
					value: {
						alias: "耗电量",
					},
				},
			});
			columnPlot.render();
			this.chart_5 = columnPlot;
		},

		//区域能耗
		chart_init_8() {
			const data = [];

			const bar = new Column("hjj-demo2-chart-8", {
				data,
				xField: "eqemo",
				yField: "value",
				//seriesField: "type",
				color: "#77cba2",
				yAxis: {
					nice: true,
					grid: {
						line: {
							style: {
								stroke: "#82868b",
								lineWidth: 1,
								lineDash: [1, 6],
								strokeOpacity: 0.7,
							},
						},
					},
					label: {
						formatter: (text) => {
							return this.kFormatter(text);
						},
					},
				},
				legend: false,
				label: false,
				meta: {
					eqemo: {
						alias: "区域",
					},
					value: {
						alias: "耗电量",
						formatter: (text) => {
							return Math.floor(text * 100) / 100;
						},
					},
				},
			});

			bar.render();
			this.chart_8 = bar
		},

		//分项能耗柱状图
		chart_init_9() {
			const data = [];
			const bar = new Column("hjj-demo2-chart-9", {
				data,
				theme: {
					colors10: ['#7dbcdd', '#6388e6', '#77cba2', '#5c6a89',],
				},
				xField: "egname",
				yField: "value",
				seriesField: "egname",
				legend: false,
				yAxis: {
					nice: true,
					grid: {
						line: {
							style: {
								stroke: "#82868b",
								lineWidth: 1,
								lineDash: [1, 6],
								strokeOpacity: 0.7,
							},
						},
					},
					label: {
						formatter: (text) => {
							return this.kFormatter(text);
						},
					},
				},
				label: false,
				meta: {
					egname: {
						alias: "能耗类型",
					},
					value: {
						alias: "耗电量",
						formatter: (text) => {
							return Math.floor(text * 100) / 100;
						},
					},
				},
			});

			bar.render();
			this.chart_9 = bar
		},

		//分项能耗环形图
		chart_init_11() {
			const data = [];

			const piePlot = new Pie("hjj-demo2-chart-11", {
				appendPadding: 10,
				data,
				theme: {
					colors10: ['#7dbcdd', '#6388e6', '#77cba2', '#5c6a89',],
				},
				angleField: "value",
				colorField: "egname",
				radius: 1,
				innerRadius: 0.5,
				pieStyle: {
					lineWidth: 0,
				},
				label: {
					type: "inner",
					offset: "-50%",
					content: "{percentage}",
					style: {
						textAlign: "center",
						fontSize: 12,
					},
				},
				interactions: [
					{type: "element-selected"},
					{type: "element-active"},
				],
				statistic: {
					title: {
						formatter: () => "",
						offsetY: -8,
						style: {
							fontSize: "0.9rem",
							color: "",
						},
					},
					content: {
						formatter: () => "",
						offsetY: -4,
						style: {
							fontSize: "1.5rem",
							fontWeight: "normal",
							color: "",
						},
					},
				},
				legend: {
					position: "left-top",
					itemName: {
						style: {
							fill: "#808695",
						},
					},
				},
				meta: {
					egname: {
						alias: "能耗类型",
					},
					value: {
						alias: "耗电量",
						formatter: (text) => {
							return Math.floor(text * 100) / 100;
						},
					},
				},
			});

			piePlot.render();

			this.chart_11 = piePlot
		},

		//获取数据
		getParams() {
			getMultipleParams({project_id: 74}).then(res => {
				console.log('res', res.data);
				this.params = res.data;
				this.chart_5.changeData(res.data.energy_d)
				this.chart_8.changeData(res.data.zone)
				this.chart_9.changeData(res.data.energy_type)
				this.chart_11.changeData(res.data.energy_type)
				let energy_trend_list = []
				let energy_trend_sum = 0
				res.data.energy_d.map(item => {
					energy_trend_list.push(Math.floor(item.value * 100) / 100)
					energy_trend_sum += item.value
				})
				this.energy_trend.series[0].data = energy_trend_list
				this.energy_trend.avg = Math.floor(energy_trend_sum / energy_trend_list.length * 100) / 100
			});
		}
	},
	mounted() {
		this.getParams()
		this.interval = setInterval(() => {
			this.getParams()
		}, 1000 * 60 * 5)
		// this.chart_init_1();
		this.chart_init_5();
		this.chart_init_8();
		this.chart_init_9();
		this.chart_init_11();
	},
	beforeDestroy() {
		clearInterval(this.interval)
	}
};
</script>

<style scoped>
</style>