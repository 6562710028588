<template>
	<!-- <b-card class="m-0 position-relative" no-body> -->
	<div class="position-relative">
		<div class="d-flex justify-content-between align-items-center">
			<b-avatar :variant="`light-${color}`" size="40">
				<feather-icon :icon="icon" size="25" />
			</b-avatar>
			<div class="truncate">
				<h2 class="mb-0 font-weight-bolder">
					{{ statistic }}
				</h2>
				<small>{{ statisticTitle }}</small>
			</div>
		</div>
		<vue-apex-charts
			:options="chartOptionsComputed"
			:series="chartData"
			class="w-100"
			style="position: absolute; bottom: 0"
			height="80%"
			type="area"

		/>
	</div>
	<!-- </b-card> -->
</template>

<script>
import { BAvatar, BCard, BCardBody } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import { areaChartOptions } from "./chartOptions";

export default {
	components: {
		VueApexCharts,
		BCard,
		BCardBody,
		BAvatar,
	},
	props: {
		icon: {
			type: String,
			required: true,
		},
		statistic: {
			type: [Number, String],
			required: true,
		},
		statisticTitle: {
			type: String,
			default: "",
		},
		color: {
			type: String,
			default: "primary",
		},
		chartData: {
			type: Array,
			default: () => [],
		},
		chartOptions: {
			type: Object,
			default: null,
		},
	},
	computed: {
		chartOptionsComputed() {
			if (this.chartOptions === null) {
				const options = JSON.parse(JSON.stringify(areaChartOptions));
				options.theme.monochrome.color = $themeColors[this.color];
				return options;
			}
			return this.chartOptions;
		},
	},
};
</script>
